import React from 'react';
import separatorImg from '../assets/separator.png';

const styles = {
  separator: {
    background: `url(${separatorImg}) center repeat-x`,
    backgroundSize: 'contain',
    height: '15px',
    width: '100%',
  },
};

function Separator() {
  return <div style={styles.separator} />;
}

export default Separator;
