import React from 'react';
import sherafGamesLogo from '../assets/sheraf-games-logo.png';
import headerBackground from '../assets/header-background.png';

function Header() {
  return (
    <header
      style={{
        backgroundImage: `url(${headerBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <img
        src={sherafGamesLogo}
        alt="Sheraf Games Logo"
        style={{ position: 'absolute', top: '17.5%%', height: '75%' }}
      />
    </header>
  );
}

export default Header;