import React from 'react';

const styles = {
  div: {
    textAlign: 'center',
    color: 'white',
    marginTop: '20px'
  }
}

function Copyright() {
  const currentYear = new Date().getFullYear();
  return (
    <div style={styles.div}>&copy; {currentYear} Sheraf Games</div>
  );
}

export default Copyright;