import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function Recap(props) {
  const handleRecaptchaChange = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <ReCAPTCHA
        sitekey="6Lff5qokAAAAACCy6F6kXz-A6nDV8k2qGRUu9ICB"
        onChange={handleRecaptchaChange}
      />
    </div>
  );
}

export default Recap;