import Header from './components/Header';
import VideoSection from './components/VideoSection';
import Separator from './components/Separator';
import FeatureSection from './components/FeatureSection'
import NewsletterSection from './components/NewsletterSection'
import SocialLinks from './components/SocialLinks'
import ReactGA from 'react-ga'
import {useEffect} from 'react'

function initializeReactGA() {
  ReactGA.initialize('G-DLWNVBTELG');
  ReactGA.pageview('/');
}

function App() {
  // Initialize ReactGA on app load
  useEffect(() => {
    initializeReactGA();
  }, []);

  return (
    <div className="App">
      <Header />
      <Separator />
      <VideoSection />
      <FeatureSection />
      <Separator />
      <NewsletterSection />
      <Separator />
      <SocialLinks />
    </div>
  );
}

export default App;