import React, { useState} from 'react';
import gif1 from '../assets/gif1.gif';
import gif2 from '../assets/gif2.gif';
import gif3 from '../assets/gif3.gif';
import image1 from '../assets/featureButton1.png';
import image2 from '../assets/featureButton2.png';
import image3 from '../assets/featureButton3.png';
import background from '../assets/feature_section_background.png';

const styles = {
  featuresSection: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  featureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '40%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  button: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '10px',
    borderRadius: '50%',
    width: '75px',
    height: '75px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    margin: '10px',
    cursor: 'pointer',
    backgroundImage: `url(${image1})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    border: '4px solid black',
    opacity: '60%'
  },
  activeButton: {
    border : '4px solid #c9930a',
    opacity: '100%',
    WebkitBoxShadow: '0px 0px 40px 5px rgba(201,147,10,1)',
    MozBoxShadow: '0px 0px 40px 5px rgba(201,147,10,1)',
    boxShadow: '0px 0px 40px 5px rgba(201,147,10,1)'

  },
  gifContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
  },
  gif: {
    width: '100%',
    border: '10px solid #46150b',
    borderRadius :  '10px'
  },
  hiddengif:{
    overflow: 'hidden'
  },
  featureTitle: {
    color: 'white',
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '5px 5px 5px black',
  },
  text: {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '5px 5px 5px black',
  },
};



function FeatureSection() {
  const [selectedFeature, setSelectedFeature] = useState(0);

  const featureData = [
    {
      gif: gif1,
      title: 'Interact with moving paintings',
      text: 'Theses paintings animate when you touch them. <br /> Meet strange characters, look for clues, and unravel mysteries.  ',
      image: image1,
    },
    {
      gif: gif2,
      title: 'Draw symbols to solve puzzles',
      text: 'These symbols will change the painting and sometimes the real world. <br /> Experiment with many symbols and see what they do!',
      image: image2,
    },
    {
      gif: gif3,
      title: 'Explore a surrealist world',
      text: 'Explore a vast open world where the laws of logic do not always apply. <br /> Discover a world influenced by the art of a painter and add your own touch to it. ',
      image: image3,
    },
  ];

  // preload
  var img = new Image();
  img.src = gif2;    
  var img2 = new Image();
  img2.src = gif3;

  //
  const handleButtonClick = (index) => {
    setSelectedFeature(index);
  };

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredButton(index);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };


  return (
    <section className="features-section" style={styles.featuresSection}>
      <div style={styles.featureContainer}>
        <div style={styles.buttonContainer}>
          {featureData.map((feature, index) => (
          <button
            key={index}
            style={{
              ...styles.button,
              backgroundImage: `url(${feature.image})`,
              ...(index === selectedFeature || index === hoveredButton) && styles.activeButton,
            }}
            onClick={() => handleButtonClick(index)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          />

          ))}
        </div>
        <h3 style={styles.featureTitle}>{featureData[selectedFeature].title}</h3>
        <p style={styles.text} dangerouslySetInnerHTML={{ __html: featureData[selectedFeature].text.replace(/\n/g, '<br/>') }}></p>
      </div>
      <div style={styles.gifContainer}>
        <img
          src={featureData[selectedFeature].gif}
          style={styles.gif}
          alt="feature"
        />
      </div>
    </section>
  );
}

export default FeatureSection;
