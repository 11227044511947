import React, { useState } from 'react';
import twitterLogo from '../assets/twitter.png'
import discordLogo from '../assets/discord.png'
import youtubeLogo from '../assets/youtube.png'
import tiktokLogo from '../assets/tiktok.png'
import Copyright from './Copyright'
import ReactGA from 'react-ga';

const styles = {
  button: {
    backgroundColor: 'transparent',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    padding: '20px',
    margin: '10px',
    fontSize: '20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    width: '40px',
    height: '40px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  twitter: {
    backgroundImage: `url(${twitterLogo})`,
  },
  discord: {
    backgroundImage: `url(${discordLogo})`,
  },
  youtube: {
    backgroundImage: `url(${youtubeLogo})`,
  },
  tiktok: {
    backgroundImage: `url(${tiktokLogo})`,
  },
  h2: {
    color: 'white',
    fontSize: '28px',
    fontWeight: 'bold',
    textShadow: '2px 2px #000',
    textAlign: 'center',
    margin : '0'
  },
  section:{
    backgroundColor: '#46150b'
  },
  press: {
    width: '20%',
    height: '100%',
    borderRadius: '0',
    padding: '20px',
    fontSize: '20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    color: 'black'
  },
}

function SocialLinks() {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleMouseclick = (button) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: button
    });
  };

  return (
    <section style={styles.section}>
      <h2 style={styles.h2}>Stay connected</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="https://twitter.com/SherafGames">
          <button
            style={{
              ...styles.button,
              ...styles.twitter,
              opacity: hoveredButton === 'twitter' ? '100%' : '50%'
            }}
            onMouseEnter={() => handleMouseEnter('twitter')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleMouseclick('TwitterButton')}
          ></button>
        </a>
        <a href="https://discord.gg/skq6dJcrED">
          <button
            style={{
              ...styles.button,
              ...styles.discord,
              opacity: hoveredButton === 'discord' ? '100%' : '50%'
            }}
            onMouseEnter={() => handleMouseEnter('discord')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleMouseclick('DiscordButton')}
          ></button>
        </a>
        <a href="https://www.youtube.com/channel/UCB_YvalKt0VSe-102P8jZag">
          <button
            style={{
              ...styles.button,
              ...styles.youtube,
              opacity: hoveredButton === 'youtube' ? '100%' : '50%'
            }}
            onMouseEnter={() => handleMouseEnter('youtube')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleMouseclick('YoutubeButton')}
          ></button>
        </a>
        <a href="https://www.tiktok.com/@sherafgames">
          <button
            style={{
              ...styles.button,
              ...styles.tiktok,
              opacity: hoveredButton === 'tiktok' ? '100%' : '50%'
            }}
            onMouseEnter={() => handleMouseEnter('tiktok')}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleMouseclick('TikTokButton')}
          >
          </button>
        </a>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button
          style={{
            ...styles.button,
            ...styles.press,
            backgroundColor: '#FFC300',
            opacity: hoveredButton === 'press' ? '100%' : '50%',
            backgroundImage: 'none',
            fontWeight: 'bold'
          }}
          onMouseEnter={() => handleMouseEnter('press')}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            window.open('https://impress.games/press-kit/sheraf-games/paint-over');
            handleMouseclick('PressButton');
          }}
        >
          Press Kit
        </button>
      </div>
      <Copyright />
    </section>
  );
};


export default SocialLinks;
