import React, { useState } from 'react';
import newsletterBg from '../assets/newsletter_background.png';
import ReactGA from 'react-ga';
import axios from 'axios';
import Recap from './Recaptcha.js'


const styles = {
  newsletterSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    backgroundImage: `url(${newsletterBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  newsletterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '30%',
    padding: '40px',
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: '2px solid white',
  },
  title: {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    margin: '10px',
    width: '100%',
    boxSizing: 'border-box'
  },
  button: {
    color: 'white',
    backgroundColor: '#46150b',
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    cursor: 'pointer',
    margin: '10px',
    width: '100%',
  },
};

function NewsletterSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

const handleSubmit = async (event) => {
    event.preventDefault();
    if (!recaptchaValue) {
      alert('Please complete the Recaptcha verification');
      return;
    }
    try {
      const response = await axios.post('https://api.sherafgames.com/submit-form', {
        name,
        email,
        recaptchaValue
      });
      if (response.status === 200) {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: 'NewsletterButton'
        });
        alert(`Thank you for subscribing with name: ${name} and email: ${email}`);
      }
    } catch (error) {
      console.error(error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
};

  return (
    <section className="newsletter-section" style={styles.newsletterSection}>
      <div style={styles.newsletterContainer}>
        <h2 style={styles.title}>Subscribe to the newsletter and get notified once the beta is out!</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" value={name} onChange={handleNameChange} style={styles.input} placeholder="Enter your name" required />
          <input type="email" value={email} onChange={handleEmailChange} style={styles.input} placeholder="Enter your email address" required />
          <Recap onChange={handleRecaptchaChange} />

          <button type="submit"         
          style={{
            ...styles.button,
            backgroundColor: isHovered ? '#506C90' : '#274155'
          }} 
          onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>Subscribe</button>
        </form>
        
      </div>
    </section>
  );
}

export default NewsletterSection;
