import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import paintOverBackground from '../assets/paintover_background.png';
import ReactGA from 'react-ga';

const styles = {
  videoSection: {
    backgroundImage: `url(${paintOverBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  title: 
  {
    color: 'white',
    fontSize: '60px'
  },
  videoContainer: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    width: '43%',
    height: '50%',
    justifyContent: 'center',
    boxSizing: 'border-box',
    margin: '1%',
  },
  text: {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '1px 1px 1px black',
  },
  button: {
    backgroundColor: '#274155',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '15px 30px',
    borderRadius: '10px',
    marginBottom: '5%',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease-in-out'
  }, video:{
    border: '10px solid #46150b',
    borderRadius: '10px'
  }
};

function handleClick() {
  ReactGA.event({
    category: 'Button',
    action: 'Click',
    label: 'WishlistButton'
  });
}

function VideoSection() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <section className="video-section" style={styles.videoSection}>
       <h1 style={styles.title}>Paint Over</h1>

      <div className="video-container" style={styles.videoContainer}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=cu5i2pHWass"
          controls={true}
          style={styles.video}
          className="youtubeVideo"
          width='100%'
          height='100%'
        />
      </div>
      <p style={styles.text}>
        Paint Over is an immersive puzzle game that invites you to explore a strange and surreal world of living paintings. <br/>
        Draw symbols on them to uncover their hidden secrets and alter the course of your adventure. <br/>
        With each brushstroke, you'll unravel new mysteries and discover the story of a painter. <br/>
        Are you ready to paint over reality and delve into the unknown?
      </p>
      <a
        href="https://store.steampowered.com/app/2339590/Paint_Over/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        style={{
          ...styles.button,
          backgroundColor: isHovered ? '#506C90' : '#274155'
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Add to your wishlist
      </a>
    </section>
  );
}

export default VideoSection;
